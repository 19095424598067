import RequestSender from './RequestSender.js';

function AddAssistantMessage(data, files) {
    return RequestSender.PostFormData('assistant/AddAssistantMessage', data, files);
}

function GetAssistantConversation(data){
    return RequestSender.Get('assistant/GetAssistantConversation', data);
}

function GetIsAssistantEnabled(){
    return RequestSender.Get('assistant/GetIsAssistantEnabled');
}

export default {
    AddAssistantMessage, GetAssistantConversation, GetIsAssistantEnabled
}