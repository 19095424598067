import { reactive } from 'vue';

// Creamos un objeto reativo para almacenar los eventos y sus callbacks
const EventBus = reactive({
  events: {}
});

// Método para suscribirse a un evento
EventBus.$on = function(event, callback) {
  if (!this.events[event]) {
    this.events[event] = [];
  }
  this.events[event].push(callback);
};

// Método para emitir un evento
EventBus.$emit = function(event, ...args) {
  if (this.events[event]) {
    this.events[event].forEach(callback => {
      callback(...args);
    });
  }
};

// Método para cancelar la suscripción a un evento
EventBus.$off = function(event, callback) {
  if (this.events[event]) {
    this.events[event] = this.events[event].filter(cb => cb !== callback);
  }
};

export default EventBus;