<template>
    <div class="messageWrapper">
        <div v-if="!message.isUserMessage || loadingMessage" class="assistantIcon">
            <img src="../../images/BotIcon.png" alt="Assistant Icon" />
        </div>

        <div class="message" :class="{'user' : message.isUserMessage, 'loadingMessage' : loadingMessage}">
            <div v-if="message.files.length > 0" class="attachmentsFilesContainer">
                <i class="bi bi-paperclip"></i>
                <label @click="ShowAttachedFiles()">
                    {{ message.files.length }} Archivos adjuntos
                </label>

                <button class="iconButton toggleAttachedFilesButton" @click="ShowAttachedFiles()">
                    <i :class="{'bi bi-chevron-down' : !showAttachedFiles, 'bi bi-chevron-up' : showAttachedFiles}"></i>
                </button>
            </div>
            <div class="filesList" :hidden="!showAttachedFiles">
                <div v-for="file in message.files" :key="file.name">
                    <i :class="icons[file.type]"></i>
                    {{ file.name }}
                </div>
            </div>
            <div v-html="formattedMessage"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AssistantMessage',
    props: {
        message: {
            type: Object,
            required: true,
        },
        loadingMessage: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            formattedMessage: '',
            showAttachedFiles: false,
            icons : {
                'Word' : 'bi bi-file-earmark-text',
                'PDF' : 'bi bi-file-earmark-text',
                'Text' : 'bi bi-image',
                'Image' : 'bi bi-image',
            }
        };
    },

    mounted() {
        this.formattedMessage = this.message.isUserMessage ? this.message.content : this.message.content.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        this.formattedMessage = this.message.isUserMessage ? this.formattedMessage : this.formattedMessage.replace(/###/g, '●');
        this.formattedMessage = this.message.isUserMessage ? this.formattedMessage : this.formattedMessage.replace(/【\d+:\d+†source】/g, '');
        this.formattedMessage = this.message.isUserMessage ? this.formattedMessage : this.formattedMessage.replace(/\*(.*?)\*/g, '<i>$1</i>');
    },

    methods: {
        ShowAttachedFiles() {
            this.showAttachedFiles = !this.showAttachedFiles;
        }
    }
};
</script>

<style scoped>
.messageWrapper {
    width: 100%;
    display: flex;
}

.message {
    width: calc(100% - 40px);
    white-space: pre-wrap;
    overflow: hidden;
    padding: 10px;
    padding-top: 5px;
    margin-bottom: 15px;
    font-size: 14px;
}

.message.user {
    background-color: rgb(245, 245, 245);
    border-radius: 15px;
    max-width: calc(100% - 30px);
    width: auto;
    margin-left: auto;
    padding-top: 7px;
}

.message:has(.attachmentsFilesContainer) {
    padding-top: 2px;
}

.assistantIcon {
    border-radius: 50%;
    background-color: var(--LativoDarkPurple);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-right: 5px;
}

.assistantIcon img {
    transform: translateX(1px);
    width: 18px;
    height: 18px;
    z-index: 0 !important;
}

.attachmentsFilesContainer {
    display: flex;
    align-items: center;
    color: rgb(45, 45, 45);
    text-decoration: underline;
    font-size: 13px;
}

.attachmentsFilesContainer label {
    cursor: pointer;
}

.toggleAttachedFilesButton {
    margin-left: auto;
}

.toggleAttachedFilesButton i {
    font-size: 16px !important;
}

.loadingMessage {
    width: auto;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: auto;
}

.bi-paperclip {
    transform: rotate(-145deg);
}

.filesList {
    color: rgb(60, 60, 60);
    font-size: 12px;
    margin-bottom: 10px;
}
</style>