<template>
    <label v-if="label != ''" style="font-size: 12px; color: gray">
        <label v-if="required" class="required" title="OBLIGATORIO">*</label>
        {{ label }}
    </label>

    <div v-if="icon!=''" class="input-with-icon pretorFormWrapper" :style="style" :class="classObject">
        <span class="input-icon">
            <i :class="icon"></i>
        </span>

        <input ref="input"
            v-model="value"
            class="pretorForm"
            :class="classObject"
            :placeholder="placeholder"
            style="font-size: 14px; width:100%"
            :disabled="disabled"
            autocomplete="off"
            :style="style"
            :type="isPassword ? 'password' : type"
            :oninput="onInputHandler"
            @click="focused=true"
            @keydown="KeydownHandler">

        <label v-if="!valid && value.length > 0 && alreadyValidated" class="required">{{ validateMessage }}</label>
        <label v-if="value.length > maxLength" class="required">CARACTERES MAXIMOS PERMITIDOS: {{ maxLength }}</label>
    </div>
    
    <div v-else :style="style" class="pretorFormWrapper">
        <input ref="input" 
            v-model="value"
            class="pretorForm"
            :placeholder="placeholder"
            style="font-size: 14px;"
            :disabled="disabled"
            autocomplete="off"
            :class="classObject"
            :style="style"
            :type="isPassword ? 'password' : type"
            :name="isPassword ? 'notsearch_password' : ''"
            :oninput="onInputHandler"
            @click="focused = true"
            @keydown="KeydownHandler">

        <label v-if="!valid && value.length > 0 && alreadyValidated" class="required">{{ validateMessage }}</label>
        <label v-if="maxLength && value.length > maxLength" class="required">CARACTERES MAXIMOS PERMITIDOS: {{ maxLength }}</label>
    </div>
</template>

<script>
export default{
    name: 'PretorInput',
    props:{
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        label:{
            type: String,
            required: false,
            default: ''
        },
        placeholder:{
            type: String,
            required: false,
            default: ''
        },
        style:{
            type: String,
            required: false,
            default: ''
        },
        required:{
            type: Boolean,
            required: false,
            default: false
        },
        validate: {
            type: RegExp,
            required: false,
            default: null
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        onInput: {
            type: Function,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        validateMessage: {
            type: String,
            required: false,
            default: ''
        },
        isPassword: {
            type: Boolean,
            required: false,
            default: false
        },
        OnEnter: {
            type: Function,
            required: false,
            default: () => {}
        },
        AutoCaps: {
            type: Boolean,
            required: false,
            default: true
        },
        Class: {
            type: String,
            required: false,
            default: ''
        },
        maxLength: {
            type: Number,
            required: false,
        }
    },
    data(){
        return {
            value: '',
            valid: false,
            showPassword: false,
            alreadyValidated: false
        }
    },
    computed: {
        classObject() {
            const classes = {
                'invalid': (!this.valid && this.value.length > 0 && this.alreadyValidated) || (this.value.length > this.maxLength)
            };
            if (this.Class !== '') {
                classes[this.Class] = true;
            }
            return classes;
        }
    },
    mounted(){
        this.valid = !this.required;
    },
    watch: {
        required: function(newValue, oldValue) {
        if (newValue !== oldValue) {
            this.CheckIsValid();
        }
        }
    },
    methods: {
        SetFocus(){
            this.$refs.input.focus();
        },

        KeydownHandler(event){            
            if(event.key == 'Enter'){
                this.OnEnter();
            }
        },

        GetValue(){
            return this.value;
        },

        SetValue(value){
            this.value = value;
            this.onInputHandler();
            this.CheckIsValid();
        },

        CheckIsValid(){
            let value = this.value.trim();
            let valid = true;

            if(this.validate && value.length > 0){
                valid = this.validate.test(value);
            }

            if(this.required){
                valid = value.length > 0 && valid;
            }

            if(this.maxLength){
                valid = value.length <= this.maxLength && valid;
            }

            this.valid = valid;
        },

        ClearField(){
            this.value = '';
            this.valid = !this.required;
            this.alreadyValidated = false;
            this.$refs.input.classList.remove('invalid');
        },

        ClearValid(){
            this.$refs.input.classList.remove('invalid');
        },

        onInputHandler(){
            if(this.AutoCaps){
                let textarea = this.$refs.input;
                let start = textarea.selectionStart;
                let end = textarea.selectionEnd;

                this.value = this.value.toUpperCase();

                this.$nextTick(() => {
                    textarea.setSelectionRange(start, end);
                });
            }

            if(this.onInput != null){
                this.onInput(this.value);
            }

            this.CheckIsValid();
        },

        GetValid(){
            if(!this.valid){
                this.alreadyValidated = true;
                this.$refs.input.classList.add('invalid');
            }

            return this.valid;
        }
    }
}
</script>

<style>
</style>